/*
  Bootstrap breakpoints
  |  xs | <  576 |
  |  sm | ≥  576 |
  |  md | ≥  768 |
  |  lg | ≥  992 |
  |  xl | ≥ 1200 |
  | xxl | ≥ 1400 |
 */

:root {
  --bs-primary: #E8912A;
  --bs-primary-rgb: 232, 145, 42;

  --blue: #030338;
}

.btn-primary {
  --bs-btn-font-weight: 600;
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: var(--bs-primary);
  --bs-btn-border-color: var(--bs-primary);
  --bs-btn-border-radius: .5rem;
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: #002e48; /* 10% */
  --bs-btn-hover-border-color: #002e48; /* 10% */
  --bs-btn-focus-shadow-rgb: var(--bs-primary-rgb);
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #000d15; /* 20% */
  --bs-btn-active-border-color: #000d15; /* 20% */
}

@media (max-width: 767px) {
  .btn-primary {
    width: 100%;
  }
}

[hidden] {
  display: unset !important;
}

html, body {
  height: 100%;
  width: 100vw;
  max-width: 100vw;
}

swd-app {
  min-height: 100%;
}

swd-hero {
  border-bottom: 8px solid var(--bs-primary);
}

swd-footer {
  border-top: 4px solid var(--bs-primary);
}

body {
  --hero-background: #004e7b;

  --main-background: #FFF;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Nunito Sans', 'Roboto', sans-serif;
}

h1, h2 {
  font-weight: 700; /* bold */
}

h3, h4 {
  font-weight: 600; /* semi-bold */
}

h5, h6 {
  font-weight: 400; /* regular */
}

h2:not(:first-child) {
  margin-top: 1.5em;
}

a,
a:link {
  color: var(--bs-primary);
}

a.btn,
a.btn:link {
  color: #FFF;
}

main {
  flex: 1;
  background: #FFF;
  padding: 40px 0;
  position: relative;
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
  z-index: 7500;
}

.larger {
  font-size: xx-large;
}

.sending {
  font-size: xx-large;
  text-align: center;
  display: block;
}

@media (max-width: 767px) {
  .call-to-action {
    flex-direction: column;
  }
}

.subtle-glow {
  position: relative;
  animation: subtle-grow 30s 2s infinite;
}

.subtle-glow:before {
  content: "";
  z-index: -1;
  box-shadow: 0 0 10px var(--bs-primary);
  position: absolute;
  inset: 0;
  border-radius: inherit;
  transform: scale(0);
  animation: subtle-glow 30s 2s infinite;
}

main > .container-md,
footer > .container-md {
  max-width: 800px;
}

.call-to-action {
  background: var(--blue);
  margin: 16px -10000px 18px;
  padding: 60px 10040px;
  font-size: 24px;
  color: #FFF;
  box-shadow: 0 4px 4px #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.call-to-action .btn {
  font-size: 24px;
  float: right;
  padding: 16px 24px;
}

form-element textarea {
  height: auto !important;
}

swd-app {
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: 100vh;
}

swd-nav {
  flex: 0;
}

swd-nav + * {
  flex: 1;
  overflow: auto;
}

swd-app > div {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

swd-app > div > :first-child {
  flex: 1;
}

.bigger {
  font-size: 1.5em;
}

body.admin {
  height: 100vh;
  max-height: 100vh;
}

body.admin swd-app {
  max-height: 100%;
}

body.admin swd-app div {
  display: flex;
  flex-direction: column;
}

body.admin swd-nav,
body.admin swd-footer {
  display: none;
}

.pac-container {
  z-index: 10000;
}

@keyframes faded {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }

  100% {
    opacity: 0;
    transform: translateX(-20px);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slide-fade-in-right {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-fade-in-left {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes subtle-grow {
  0% {
    transform: scale(1);
  }

  5% {
    transform: scale(1.05);
  }

  30% {
    transform: scale(1);
  }
}

@keyframes subtle-glow {
  0% {
    transform: scale(0);
  }

  5% {
    transform: scale(1);
  }

  30% {
    transform: scale(0);
  }
}
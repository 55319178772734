:root {
  --bs-primary: #e8912a;
  --bs-primary-rgb: 232, 145, 42;
  --blue: #030338;
}

.btn-primary {
  --bs-btn-font-weight: 600;
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: var(--bs-primary);
  --bs-btn-border-color: var(--bs-primary);
  --bs-btn-border-radius: .5rem;
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: #002e48;
  --bs-btn-hover-border-color: #002e48;
  --bs-btn-focus-shadow-rgb: var(--bs-primary-rgb);
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #000d15;
  --bs-btn-active-border-color: #000d15;
}

@media (max-width: 767px) {
  .btn-primary {
    width: 100%;
  }
}

[hidden] {
  display: unset !important;
}

html, body {
  height: 100%;
  width: 100vw;
  max-width: 100vw;
}

swd-app {
  min-height: 100%;
}

swd-hero {
  border-bottom: 8px solid var(--bs-primary);
}

swd-footer {
  border-top: 4px solid var(--bs-primary);
}

body {
  --hero-background: #004e7b;
  --main-background: #fff;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Nunito Sans, Roboto, sans-serif;
}

h1, h2 {
  font-weight: 700;
}

h3, h4 {
  font-weight: 600;
}

h5, h6 {
  font-weight: 400;
}

h2:not(:first-child) {
  margin-top: 1.5em;
}

a, a:link {
  color: var(--bs-primary);
}

a.btn, a.btn:link {
  color: #fff;
}

main {
  width: 100%;
  max-width: 1800px;
  z-index: 7500;
  background: #fff;
  flex: 1;
  margin: 0 auto;
  padding: 40px 0;
  position: relative;
}

.larger {
  font-size: xx-large;
}

.sending {
  text-align: center;
  font-size: xx-large;
  display: block;
}

@media (max-width: 767px) {
  .call-to-action {
    flex-direction: column;
  }
}

.subtle-glow {
  animation: 30s 2s infinite subtle-grow;
  position: relative;
}

.subtle-glow:before {
  content: "";
  z-index: -1;
  box-shadow: 0 0 10px var(--bs-primary);
  border-radius: inherit;
  animation: 30s 2s infinite subtle-glow;
  position: absolute;
  inset: 0;
  transform: scale(0);
}

main > .container-md, footer > .container-md {
  max-width: 800px;
}

.call-to-action {
  background: var(--blue);
  color: #fff;
  justify-content: space-between;
  align-items: center;
  margin: 16px -10000px 18px;
  padding: 60px 10040px;
  font-size: 24px;
  display: flex;
  box-shadow: 0 4px 4px #000;
}

.call-to-action .btn {
  float: right;
  padding: 16px 24px;
  font-size: 24px;
}

form-element textarea {
  height: auto !important;
}

swd-app {
  max-height: 100vh;
  flex-direction: column;
  display: flex;
  position: relative;
}

swd-nav {
  flex: 0;
}

swd-nav + * {
  flex: 1;
  overflow: auto;
}

swd-app > div {
  flex-direction: column;
  display: flex;
  overflow-x: hidden;
}

swd-app > div > :first-child {
  flex: 1;
}

.bigger {
  font-size: 1.5em;
}

body.admin {
  height: 100vh;
  max-height: 100vh;
}

body.admin swd-app {
  max-height: 100%;
}

body.admin swd-app div {
  flex-direction: column;
  display: flex;
}

body.admin swd-nav, body.admin swd-footer {
  display: none;
}

.pac-container {
  z-index: 10000;
}

@keyframes faded {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }

  100% {
    opacity: 0;
    transform: translateX(-20px);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slide-fade-in-right {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-fade-in-left {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes subtle-grow {
  0% {
    transform: scale(1);
  }

  5% {
    transform: scale(1.05);
  }

  30% {
    transform: scale(1);
  }
}

@keyframes subtle-glow {
  0% {
    transform: scale(0);
  }

  5% {
    transform: scale(1);
  }

  30% {
    transform: scale(0);
  }
}

/*# sourceMappingURL=index.1a1a2efc.css.map */
